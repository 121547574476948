
/**
 * DropDown menu form the tp right
 * View user profile
 * user logOut.
 *
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";

//@ts-ignore
import { HeaderDropdown as AppHeaderDropdown } from "@coreui/vue";
@Component({
  name: "DefaultHeaderDropdownAccnt",
  components: {
    AppHeaderDropdown
  },
  computed: {
    ...mapGetters(["getCurrentUser", "isUserAdmin", "isIbotech", "isIbotechConstructionUser", "isIbotechSignMeasurementUser", "isIbotechSignMeasurementAdmin"])
  },
})
export default class DefaultHeaderDropdownAccnt extends Vue {
  /*---------- Vuex   ----------*/
  @Prop({ default: "", type: String }) root!: string;

  private readonly isIbotechSignMeasurementUser!: boolean;
  private readonly isIbotechSignMeasurementAdmin!: boolean;
  isIbotechApp: boolean = false;

  mounted() {
    if(this.isIbotechSignMeasurementUser || this.isIbotechSignMeasurementAdmin) {
      this.isIbotechApp = true;
    }
  }

  /*----------  Methods  ----------*/
  logout() {
    const url = this.$keycloak.createLogoutUrl?.() || "";
    window.location.replace(url);
  }
}
